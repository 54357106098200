import React from 'react'
import Wrapper from "./Wrapper"
import SearchCategories from './SearchCategories';


const Home = () => {
  return (
    <Wrapper >
       <SearchCategories/>      
    </Wrapper>
  )
}

export default Home
