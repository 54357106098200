export const student = {
  name: "Name - {name}",
  id: "Student ID - {id}",
  contact: {
    phone: "Phone: {phone}",
  },
  projects: [
    {
      name: "Camera",
      desc: "Photo",
    },
    {
      name: "Foods",
      desc: "Apple",
    },
  ],
};
