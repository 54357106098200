import React, {useState} from "react";
import {useLazyQuery, useMutation} from "@apollo/client";
import {GET_VOTER, GET_VOTERNIN, SendVoterData} from "../Queries/VotersQueries.js";

import Spinner from "./Spinner";
import {FaUser} from "react-icons/fa";
import nextId from "react-id-generator";
import InputMask from 'react-input-mask';

//kubadili lugha
import {localizationKeys} from "../i18n/keys";
import {useIntl} from "react-intl";
//sweetlert pop up
import Swal from 'sweetalert';
import './Header.scss'; // borders amination

// import Col from 'react-bootstrap/Col';
// import Form from 'react-bootstrap/Form';
// import { Form, Button, FormGroup, FormControl, ControlLabel } from "react-bootstrap";

const SearchPersonalInfoModal = () => {

    const intl = useIntl(); //kubadilisha lugha
    const [voterid, setVoterId] = useState("");
    const [birthYear, setBirthYear] = useState("");
    const [national_id, setVoterNIN] = useState("");

    const [contact_mobile_no, setVoterPN] = useState("");
    // const [Nmtaa, setnewmtaa] = useState("");
    // const [Nward, setnewward] = useState("");
    // const [registration_ward_id, setPNWADID] = useState("");
    // const [registration_village_id, setPNVID] = useState("");

    const application_reference_no = nextId("PER-INFO-");


    const [addShowForm, setAddShowForm] = useState(true);
    const [addShowForm1, setAddShowForm1] = useState(true);
    const [addShowForm2, setAddShowForm2] = useState(true);
    const [hideModal, sethideModal] = useState(true);

    const [getVoter, {loading: loadingqf, error: errorqf, data: dataqf}] = useLazyQuery(GET_VOTER);
    const [getVoterNIN, {loading: loadingqt, error: errorqt, data: dataqt}] = useLazyQuery(GET_VOTERNIN);
    const [SendVoterDataz, {loading: loadingsn, error: errorsn, data: datasn}] = useMutation(SendVoterData);
    const [emptyFieldVoter, setEmptyField] = useState(false);
    const [emptyFieldVoter12, setEmptyFieldV12] = useState(false);
    const [emptyFieldAge, setEmptyFieldAge] = useState(false);
    const [emptyFieldNIDA, setEmptyFieldNIDA] = useState(false);


   

    const handleChange = (e) => {
        const { value } = e.target;
        // const VoterCheck = /^\d{10}$/.test(value);
        setVoterId(value);
      };
    const handleVoterSearch = async (e) => {
        e.preventDefault();
        // if (voterid === '' || birthYear === '') {

       
            if (voterid === '' ) {
            setEmptyField(true)
        }else if (voterid >= 10 ) {
                setEmptyFieldV12(true)
         }else if ( birthYear === '') {
            console.log("hello --> " + voterid); 
            setEmptyField(false)
            setEmptyFieldAge(true)

            if (addShowForm) setAddShowForm(true)
        } else {
            setEmptyField(false)
            setEmptyFieldAge(false)

            // if (!addShowForm) setAddShowForm(false)
            // if (addShowForm2) setAddShowForm2(false)
            // if (!addShowForm2) setAddShowForm2(true)
            if (addShowForm) setAddShowForm(false)
            if (!addShowForm) setAddShowForm(true)
            if (addShowForm2) setAddShowForm2(false)
            if (!addShowForm2) setAddShowForm2(true)
            const unvoter = voterid.replace(/[^\d]/g, '');
            const dob = birthYear.replace(/[^\d]/g, '');
            await getVoter({
                variables: {voter_id: unvoter, birthYear: parseInt(dob)},
                //    variables: { voter_id: 100000000064, birthYear: 1965 },

            });
        }
    };


    // const handleVoterSearch = async (e) => {
    //     if (addShowForm) setAddShowForm(false)
    //     if (!addShowForm) setAddShowForm(true)
    //     if (addShowForm2) setAddShowForm2(false)
    //     if (!addShowForm2) setAddShowForm2(true)
    
    //          const unvoter = voterid.replace(/[^\d]/g, '');
    //        // const unvoter = voterids.replace(/[^\d]/g, '');
    //         const dob = birthYear.replace(/[^\d]/g, '');
    //         // console.log('TEST'+unvoter);
    //         // console.log('TEST1'+voterids);
    
    //     e.preventDefault();
    //     await getVoter({
    //         variables: { voter_id: unvoter, birthYear: parseInt(dob) },
    //         //    variables: { voter_id: 100000000064, birthYear: 1965 },
    
    //     });
    //     //setVoterId("");
    //    // setBirthYear("");
    //   };

    const handleChangeNIDA = (e) => {
        const { value } = e.target;
        // const VoterCheck = /^\d{10}$/.test(value);
        setVoterNIN(value);
      };
    const handleVoterNIN = async (e) => {
        e.preventDefault();
        if (national_id === '' ) {
            setEmptyFieldNIDA(true)
        } else {
        if (addShowForm1) setAddShowForm1(false)
        if (!addShowForm1) setAddShowForm1(true)
        if (addShowForm2) setAddShowForm2(false)
        if (!addShowForm2) setAddShowForm2(true)
        const nida_no = national_id.replace(/[^\d]/g, '');

      
        await getVoterNIN({
            variables: {national_id: nida_no},
        });
    }
        // setVoterNIN("");

    };
// useEffect(()=>{
//     if(dataqf){
//       setnewward(dataqf.voter.registrationcenter.village_mtaa.ward.wardid)
//       setnewmtaa(dataqf.voter.registrationcenter.village_mtaa.villageid)

//     }

//   },dataqf)

    const handleVoterPN = async (e) => {
        const registration_category_id = 1;
        if (addShowForm2) setAddShowForm2(false)
        const unvoter = voterid.replace(/[^\d]/g, '');
        // const dob = birthYear.replace(/[^\d]/g, '');
        const phonetz = contact_mobile_no.replace(/[^\d]/g, '');
        // Nward  Nmtaa
        const nuward = 1;
        const numtaa = 1;
        const nida_no = national_id.replace(/[^\d]/g, '');
        e.preventDefault();
        await SendVoterDataz({
            variables: {
                application_reference_no: application_reference_no,
                registration_category_id: registration_category_id,
                contact_mobile_no: phonetz,
                voter_id: unvoter,
                national_id: nida_no,
                registration_ward_id: nuward,
                registration_village_id: numtaa
            },
        });
        //setVoterNIN("");

    };


    const showAlert = (alert) => {
        if (hideModal) sethideModal(false)
        if (alert === 'successvoter') {
            Swal({
                title: intl.formatMessage({id: localizationKeys.general.ndugumpigakura}),
                text: intl.formatMessage({id: localizationKeys.general.ombilimekamilika}),
                icon: "success",
                //buttons: true,
                buttons: intl.formatMessage({id: localizationKeys.general.funga}),


            })
                .then((willDelete) => {
                    if (willDelete) {

                        window.location.reload();

                    } else {
                        // Swal("!");
                        window.location.reload();

                    }
                });

        } else if (alert === 'sendvoterfail') {

            Swal({
                title: intl.formatMessage({id: localizationKeys.general.ndugumpigakura}),
                text: intl.formatMessage({id: localizationKeys.general.ombihalijakamilika}),
                icon: "warning",
                //buttons: true,
                buttons: intl.formatMessage({id: localizationKeys.general.funga}),


            })
                .then((willDelete) => {
                    if (willDelete) {

                        window.location.reload();

                    } else {
                        // Swal("!");
                        window.location.reload();

                    }
                });


        } else if (alert === 'voternotfount') {

            Swal({
                title: intl.formatMessage({id: localizationKeys.general.ndugumpigakura}),
                text: intl.formatMessage({id: localizationKeys.general.taarifahazipo}),
                icon: "warning",
                //buttons: true,
                buttons: intl.formatMessage({id: localizationKeys.general.funga}),


            });
        }
            else if (alert === 'NIDAnotfount') {

                Swal({
                    title: intl.formatMessage({id: localizationKeys.general.ndugumpigakura}),
                    // text: intl.formatMessage({id: localizationKeys.general.taarifahazipo}),
                    text:  "Taarifa zako hazijapatikana, tafadhali ingiza Namba ya Kitambulisho cha Taifa (NIDA) au fika kwenye kituo chako kwa ajili ya kuendelea na uboreshaji.",
                    icon: "warning",
                    //buttons: true,
                    buttons: intl.formatMessage({id: localizationKeys.general.funga}),
    
    
                })
                .then((willDelete) => {
                    if (willDelete) {

                        window.location.reload();

                    } else {
                        // Swal("!");
                        window.location.reload();
                    }
                });


        }


    };
    const handleCloseModalClick = ()=>{
        setEmptyField(false);
    }

//     const [phone, setPhone] = useState("");
//   const [isValid, setIsValid] = useState(false);
//     const handleChange = (event) => {
//         const { value } = event.target;
//         const isValidPhone = /^\d{10}$/.test(value);
//         setIsValid(isValidPhone);
//         setVoterId(value);
//       };
    
//       const handleSubmit = (event) => {
       
//         // Perform actions on submit, e.g., API requests, form submission, etc.
//         const unvoter = voterid.replace(/[^\d]/g, '');
//          const dob = birthYear.replace(/[^\d]/g, '');
//          event.preventDefault();
//       getVoter({
//          variables: { voter_id: unvoter, birthYear: parseInt(dob) },
//          //    variables: { voter_id: 100000000064, birthYear: 1965 },
 
//      });
//         console.log("Phone submitted:", voterid);
//       };



    return (


        <div>
            <ol style={{marginLeft: "-10px"}}>
                <li>
                     {intl.formatMessage({id: localizationKeys.general.landing_page2})} <br/>
                    <ol>
                        <li> {intl.formatMessage({id: localizationKeys.general.landing_page2a})}</li>
                        <li>{intl.formatMessage({id: localizationKeys.general.landing_page2b})} </li>
                        <li>{intl.formatMessage({id: localizationKeys.general.landing_page2c})} </li>
                        <li>   {intl.formatMessage({id: localizationKeys.general.landing_page2d})}</li>
                    </ol>
                </li>
                <li> {intl.formatMessage({id: localizationKeys.general.landing_page2e})}</li>
            </ol>
           {/* <center style={{marginTop:"8%"}}><b>  {intl.formatMessage({id: localizationKeys.general.bonyeza})}  &#x21B4; <br/> </b></center> */}
            {/* <center> <button style={{backgroundColor: "#fd7e14", width:"50%"}}
                    type="button"
                    className="btn  btn-lg  "
                    data-bs-toggle="modal"
                    data-bs-target="#searchPersonalInfoModal"
            >
                <div className="d-flex align-items-left" style={{
                    'color': '#fff',
                    'font-size': '15px',
                    'font-weight': '1200',
                    'font-family': 'Book Antique'
                }}>
                    <FaUser className="icon"/>
                    <div><b> {intl.formatMessage({id: localizationKeys.general.BtnBinafsi})} </b></div>
                </div>
            </button> 
            </center>*/}


            {hideModal ?

                <div
                    className="modal fade"
                    id="searchPersonalInfoModal"
                    tabIndex="-1"
                    aria-labelledby="searchPersonalInfoModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-lg"  style={{marginTop:"10%"}}>
                        {/* <div className="modal-content p-3 border border-2 rounded-3 border-dark has-background-light  boxpaul2xp "  style={{    } }  > */}
                        {/* <div className="modal-content boxp "> */}
                        <div className="modal-content border-0">

                            <div className="modal-header">
                                <h5 className="modal-title" id="searchPersonalInfoModalLabel">
                                    {intl.formatMessage({id: localizationKeys.general.fomutaarifabinafsi})}
                                </h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    onClick={handleCloseModalClick}
                                ></button>
                            </div>

                            {addShowForm ?
                                <form onSubmit={handleVoterSearch}>
                                 {/* <form onSubmit={handleSubmit}> */}
                                    <div className="modal-body">
                                        <div className="form-floating mb-3">
                                            <InputMask 
                                                mask='T 9999 9999 999 9'
                                                // mask="999-999-9999"
                                                type="text"
                                                name="voterid"
                                                value={voterid}
                                                 //onChange={(e) => setVoterId(e.target.value)}
                                                onChange={handleChange}
                                                className="form-control" 
                                                id="floatingInput"
                                                placeholder="Enter Voter ID"
                                                autoComplete="none"
                                                // required
                                                


                                            />
     
                                                {emptyFieldVoter === true ? (
                                                <span style={{color: "red"}}>{intl.formatMessage({id: localizationKeys.general.JazaVoter})} </span>) : null}
                                                {emptyFieldVoter12 === true ? (
                                                <span style={{color: "red"}}>Namba ya mpiga kura Hazijatimia</span>) : null}   

                                            <label htmlFor="floatingInput"> {intl.formatMessage({id: localizationKeys.general.nambampigakura})}  </label>

          
   

                                        </div>
                                        <div className="form-floating mb-3">
                                            <InputMask
                                                mask='9999'
                                                type="text"
                                                name="birthYear"
                                                value={birthYear}
                                                onChange={(e) => setBirthYear(e.target.value)}
                                                className="form-control"
                                                id="floatingInput"
                                                placeholder="Enter Your Year Of Birth"
                                                autoComplete="none"
                                                // required
                                                errorMessage="Jaza Mwaka wa  Mpiga Kura"


                                            />
                                            <label
                                                htmlFor="floatingInput">{intl.formatMessage({id: localizationKeys.general.mwakampigakura})} </label>

                                            {emptyFieldAge === true ? (
                                                <span style={{color: "red"}}>{intl.formatMessage({id: localizationKeys.general.JazaAge})}</span>) : null}
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button style={{
                                            'background-color': '#fd7e14',
                                            'color': '#fff',
                                            'font-family': 'Varela Round'
                                        }}
                                                className="w-100 btn btn-lg  mb-2"
                                                type="submit"
                                        >
                                            {intl.formatMessage({id: localizationKeys.general.endelea})}
                                        </button>

                                    </div>
                                </form>

                                : null
                            }
                            {loadingqf && (
                                <div className="d-flex justify-content-center">
                                    <Spinner/>
                                </div>
                            )}

                            {errorqf && (
                                <p className="alert alert-danger m-3">
                                    {/* Error occurred: {errorqf.message} */}
                                    {showAlert('voternotfount')}

                                </p>
                            )}
                            {dataqf && dataqf.voterPersonalInfo ? (

                                <>
                                    <div
                                        className="d-flex justify-content-flex-start m-3  p-3 border rounded-3 border-dark-1  has-background-light">

                                        <p>
                                            <h6>
                                                <b> {intl.formatMessage({id: localizationKeys.general.Taarifa_binafsi})} </b>
                                            </h6>

                                            <b>  {intl.formatMessage({id: localizationKeys.general.jinakamili})}: </b>
                                            <span>{dataqf.voterPersonalInfo.firstname} {dataqf.voterPersonalInfo.middlename} {dataqf.voterPersonalInfo.surname}</span>

                                            <br/>

                                            <b> {intl.formatMessage({id: localizationKeys.general.jins})}: </b>
                                            <span>{dataqf.voterPersonalInfo.sex} </span>

                                        </p>


                                        {/* <span>{moment(dataqf?.voterPersonalInfo.birthdate).format("LL")}</span> */}


                                    </div>
                                    <div>
                                        {addShowForm1 ?
                                            <form onSubmit={handleVoterNIN}>
                                                <div className="modal-body">
                                                    <div className="form-floating mb-3">
                                                        <InputMask
                                                            mask='99999999 99999 99999 99'
                                                            type="text"
                                                            name="national_id"
                                                            value={national_id}
                                                            onChange={handleChangeNIDA}
                                                            // onChange={(e) => setVoterNIN(e.target.value)} handleChangeNIDA
                                                            className="form-control"
                                                            id="floatingInput"
                                                            placeholder="Year Of Birth"
                                                            autoComplete="none"
                                                            // required
                                                            seterrorMessage="Jaza Namba ya NIDA"
                                                        />
                                                        <label
                                                            htmlFor="floatingInput">{intl.formatMessage({id: localizationKeys.general.nambayanida})}</label>
                                                             {emptyFieldNIDA === true ? (
                                                <span style={{color: "red"}}> {intl.formatMessage({id: localizationKeys.general.JazaNIDA})} </span>) : null}
                                                    </div>
                                                </div>
                                                <div className="modal-footer">
                                                    <button style={{
                                                        'background-color': '#fd7e14',
                                                        'color': '#fff',
                                                        'font-family': 'Varela Round'
                                                    }}
                                                            className="w-100 btn btn-lg "
                                                            type="submit"
                                                    >
                                                        {intl.formatMessage({id: localizationKeys.general.endelea})}
                                                    </button>
                                                </div>
                                            </form>

                                            : null
                                        }

                                        {loadingqt && (
                                            <div className="d-flex justify-content-center">
                                                <Spinner/>
                                            </div>
                                        )}

                                        {errorqt && (
                                            <p className="alert alert-danger m-3">
                                                {/* Error occurred: {errorqt.message} */}
                                                {/* {showAlert('voternotfount')} */}
                                                {showAlert('NIDAnotfount')}

                                            </p>
                                        )}
                                        {dataqt && dataqt.nida ? (

                                            <>

                                                <div
                                                    className="d-flex justify-content-flex-start m-3  p-3 border rounded-3 border-dark-1 has-background-light">
                                                    <p>
                                                        {/*NIDA ID: <span>{dataqt.nida.national_id}</span> */}
                                                        <center><h6>
                                                            <b> {intl.formatMessage({id: localizationKeys.general.zinazoboreshwa})} </b>
                                                        </h6></center>

                                                        <b> {intl.formatMessage({id: localizationKeys.general.jinakamili})}: </b>
                                                        <span>{dataqt.nida.firstname} {dataqt.nida.middlename} {dataqt.nida.surname}</span>

                                                        <br/>

                                                       {/* <b> {intl.formatMessage({id: localizationKeys.general.jins})}: </b>
                                                        <span>{dataqt.nida.sex.sex_abbrv_sw} </span>*/}

                                                    </p>


                                                </div>

                                            </>
                                        ) : dataqt && !loadingqt ? (
                                            <p className="alert alert-danger m-3">
                                                {showAlert('NIDAnotfount')}
                                                {/* Hazijapatikana taarifa zako za NIDA.*/}
                                            </p>
                                        ) : null}


                                    </div>


                                    {addShowForm2 && dataqt && dataqt.nida ?

                                        <form onSubmit={handleVoterPN}>
                                            <div className="modal-body">
                                                <div className="form-floating mb-3">


                                                    <InputMask
                                                        mask='255 999 999 999'
                                                        type="text"
                                                        name="contact_mobile_no"
                                                        value={contact_mobile_no}
                                                        onChange={(e) => setVoterPN(e.target.value)}
                                                        className="form-control"
                                                        id="floatingInput"
                                                        placeholder="Year Of Birth"
                                                        autoComplete="none"
                                                        required
                                                    />
                                                    <label
                                                        htmlFor="floatingInput">{intl.formatMessage({id: localizationKeys.general.namba})}</label>
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <button style={{
                                                    'background-color': '#fd7e14',
                                                    'color': '#fff',
                                                    'font-family': 'Varela Round'
                                                }}
                                                        className="w-100 btn btn-lg "
                                                        type="submit"
                                                >
                                                    {intl.formatMessage({id: localizationKeys.general.tuma})}
                                                </button>
                                            </div>
                                        </form>

                                        : null
                                    }


                                    {loadingsn && (
                                        <div className="d-flex justify-content-center">
                                            <Spinner/>
                                        </div>
                                    )}

                                    {errorsn && (
                                        <p className="alert alert-danger m-3">
                                            Error occurred: {errorsn.message}
                                        </p>
                                    )}
                                    {datasn && datasn.createOnlineApplication ? (
                                        <>
                                            {showAlert('successvoter')}
                                            {/* <div className="d-flex justify-content-evenly m-3  p-3  has-background-light">
                  <p> <b> <i>
                   {intl.formatMessage({ id: localizationKeys.general.ombilimekamilika })}
                  </i> </b></p>

                   </div> */}
                                        </>
                                    ) : datasn && !loadingsn ? (
                                        <p className="alert alert-danger m-3">
                                            {showAlert('sendvoterfail')}
                                            {intl.formatMessage({id: localizationKeys.general.ombihalijakamilika})}


                                        </p>
                                    ) : null} {/*modal contact last form  */}


                                </>
                            ) : dataqf && !loadingqf ? (
                                <p className="alert alert-danger m-3">
                                    {showAlert('voternotfount')}
                                    {intl.formatMessage({id: localizationKeys.general.taarifahazipo})} 
                                </p>
                            ) : null}
                        </div>
                    </div>
                </div>
                : null} {/*modal hide  */}
        </div>


    );
};

export default SearchPersonalInfoModal;
