export const general = {
  jamuhuri: "THE UNITED REPUBLIC OF TANZANIA ", // JAMHURI YA MUUNGANO WA TANZANIA
  tume:"NATIONAL ELECTORAL COMMISSION ", // TUME YA TAIFA YA UCHAGUZI
  mfumo : " ONLINE VOTERS REGISTRATION SYSTEM", // MFUMO WA KUBORESHA TAARIFA ZA MPIGA KURA
  binafsi: " Update personal Information", //Bonyeza Hapa Kuboresha Taarifa Binafsi
  kituo: "Transfer Registration Center", //  Bonyeza Hapa Kujihamisha Kituo
  landing_page1: " Online Voter Registration system allows Voters to initiate the process in the following;",
  landing_page2: " 1 . For update Personal information, Voters shall provide and use Voter number (found on the Voter's Card) and National Identification Number in order to access the system.",
  landing_page3: " 2. For transferring of Registration Center from one Area to another, shall use a Voter Number and Year of Birth in order to access the system  ",
  landing_page4:  " 3.  Update both personal information and Transfer Registration Center information from the previous to another location shall use Voter ID and National ID for accessing the system.",
  kujihakiki: "to review if your information is correct",
  bonyeza:"Click here ",
  
  vyote: "Update and Tranfer Registration Center ",// Bonyeza Hapa Kufanya Vyote
  fomutaarifabinafsi:" FORM OF PERSONAL INFORMATION", // FOMU YA TAARIFA BINAFSI
  nambampigakura:"ENTER VOTER NUMBER ", // Andika Namba ya Mpiga Kura
  mwakampigakura:"ENTER YEAR OF BIRTH ", //Andika Mwaka Wako Kuzaliwa
  endelea :"Procced", //Endelea
  jinakamili : "FULLNAME",
  nambayanida : "WRITE NIDA NUMBER", //Andika NIDA Namba
  namba : "ENTER MOBILE NUMBER",
  tuma : "Submit",
  ombilimekamilika:" Your Request has been successful Submited, You will soon be notified by SMS.", //  Ndugu Mpiga Kura, Ombi lako limepokelewa na utapokea ujumbe mfupi kuthibitisha ombi lako.
  ombihalijakamilika : " Your Request is unsuccessful, ", //Imeshindikana Kutuma ombi
  taarifahazipo : " Your Information doesn't found Prease contact us for more infomation. Thank you ", // Hazijapatikana Taarifa zako wasiliana nasi kupata taarifa kamili
  fomubadilikituo:" FORM OF REGISTRATION TRANSFER", // FOMU YA TAARIFA BINAFSI
  mkoa: "Region",
  halmashauri: "COUNCIL",
  jimbo: "CONSTITUENCY",
  kata: "WARD",
  mtaa :" VILLAGE/STREET",
  kituoni :"REGISTRATIONCENTER",
  kituosasa:"CURRENT REGISTRATION CENTER INFORMATION",//TAARIFA ZA KITUO CHA SASA CHA MPIGA KURA
  kituokipya:"NEW REGISTRATION CENTER INFORMATION",//ENEO ULIOPO SASA
  chaguamkoa: "CHOOSE REGION",
  chaguahalmashauri: " CHOOSE COUNCIL",
  chaguajimbo: "CHOOSE CONSTITUENCY",
  chaguakata: " CHOOSE WARD",
  chaguamtaa :" CHOOSE VILLAGE/STREET",
  chaguakituoni :"CHOOSE REGISTRATIONCENTER",
  fomubadilivyote:"FORM TO PERFORM BOTH ACTIVITY", // FOMU YA KUBADILI TAARIFA ZOTE
  ndugumpigakura :"Dear Voter,",
   funga :"Close",
   Taarifa_binafsi :"VOTER CURRENT INFORMATION",
   zinazoboreshwa :"VOTER INFORMATION WILL BE UPDATED",

};
