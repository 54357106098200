import React, {useEffect, useState} from "react";
import {useLazyQuery, useMutation, useQuery} from "@apollo/client";
import {
    GET_CONS,
    GET_COUCILS,
    GET_KATA_CONS,
    GET_MTAA_KATA,
    GET_REGIONS,
    GET_VOTER,
    GET_VOTERNIN,
    GET_WARD,
    SendVoterData1
} from "../Queries/VotersQueries.js";

import Spinner from "./Spinner";
import {FaUser} from "react-icons/fa";
import nextId from "react-id-generator";
import InputMask from 'react-input-mask';

//kubadili lugha
import {localizationKeys} from "../i18n/keys";
import {useIntl} from "react-intl";

import Swal from 'sweetalert';

const SearchVyoteInfoModal = () => {
    const intl = useIntl(); //kubadilisha lugha
    const [voterid, setVoterId] = useState("");
    const [birthYear, setBirthYear] = useState("");
    const [national_id, setVoterNIN] = useState("");

    const [region, setCHR] = useState("");
    const [council, setCHCO] = useState("");
    const [ward, setCHWA] = useState("");
    const [mtaa, setCHMTAA] = useState("");


    const [contact_mobile_no, setVoterPN] = useState("");
    const [Nmtaa, setnewmtaa] = useState("");
    const [Nward, setnewward] = useState("");
    const [registration_ward_id, setPNWADID] = useState("");
    const [registration_village_id, setPNVID] = useState("");

    const [selectedCouncils, setSelectedCouncils] = useState([]);
    const [selectedConstituencies, setSelectedConstituencies] = useState([]);
    const [selectedWard, setselectedWard] = useState([]);
    const [selectedMtaa, setselectedMtaa] = useState([]);
    const [hideModal, sethideModal] = useState(true);  //modal pop up close


    const getRegions = useQuery(GET_REGIONS)
    const Rerror = getRegions.error
    const Rloading = getRegions.loading
    const getCouncils = useQuery(GET_COUCILS);
    const getCons = useQuery(GET_CONS);
    const getWard = useQuery(GET_WARD);
    const [getWardCouncil, {loading: loadingqz, error: errorqz, data: dataqz}] = useLazyQuery(GET_KATA_CONS);
    const [getMtaaWard, {loading: loadingmtaa, error: errormtaa, data: datamtaa}] = useLazyQuery(GET_MTAA_KATA);
    const application_reference_no = nextId("ALL-INFO-");

    const [addShowForm, setAddShowForm] = useState(true);
    const [addShowForm1, setAddShowForm1] = useState(true);
    const [addShowForm2, setAddShowForm2] = useState(true);

    const [getVoter, {loading: loadingqf, error: errorqf, data: dataqf}] = useLazyQuery(GET_VOTER);
    const [getVoterNIN, {loading: loadingqt, error: errorqt, data: dataqt}] = useLazyQuery(GET_VOTERNIN);
    const [SendVoterDataz, {loading: loadingsn, error: errorsn, data: datasn}] = useMutation(SendVoterData1);
    const [emptyFieldVoter, setEmptyField] = useState(false);
    const [emptyFieldVoter12, setEmptyFieldV12] = useState(false);
    const [emptyFieldAge, setEmptyFieldAge] = useState(false);
    const [emptyFieldNIDA, setEmptyFieldNIDA] = useState(false);


   

    const handleChange = (e) => {
        const { value } = e.target;
        // const VoterCheck = /^\d{10}$/.test(value);
        setVoterId(value);
      };
    const handleVoterSearch = async (e) => {
        e.preventDefault();
        // if (voterid === '' || birthYear === '') {

       
            if (voterid === '' ) {
            setEmptyField(true)
        }else if (voterid >= 10 ) {
                setEmptyFieldV12(true)
         }else if ( birthYear === '') {
            console.log("hello --> " + voterid); 
            setEmptyField(false)
            setEmptyFieldAge(true)

            if (addShowForm) setAddShowForm(true)
        } else {
            setEmptyField(false)
            setEmptyFieldAge(false)

            if (addShowForm) setAddShowForm(false)
            if (!addShowForm) setAddShowForm(true)
            if (addShowForm2) setAddShowForm2(false)
            if (!addShowForm2) setAddShowForm2(true)
            const unvoter = voterid.replace(/[^\d]/g, '');
            const dob = birthYear.replace(/[^\d]/g, '');
            await getVoter({
                variables: {voter_id: unvoter, birthYear: parseInt(dob)},
                //    variables: { voter_id: 100000000064, birthYear: 1965 },

            });
        }
    };


    // const handleVoterSearch = async (e) => {
    //     if (addShowForm) setAddShowForm(false)
    //     if (!addShowForm) setAddShowForm(true)
    //     if (addShowForm2) setAddShowForm2(false)
    //     if (!addShowForm2) setAddShowForm2(true)
    
    //          const unvoter = voterid.replace(/[^\d]/g, '');
    //        // const unvoter = voterids.replace(/[^\d]/g, '');
    //         const dob = birthYear.replace(/[^\d]/g, '');
    //         // console.log('TEST'+unvoter);
    //         // console.log('TEST1'+voterids);
    
    //     e.preventDefault();
    //     await getVoter({
    //         variables: { voter_id: unvoter, birthYear: parseInt(dob) },
    //         //    variables: { voter_id: 100000000064, birthYear: 1965 },
    
    //     });
    //     //setVoterId("");
    //    // setBirthYear("");
    //   };
      

    const handleChangeNIDA = (e) => {
        const { value } = e.target;
        // const VoterCheck = /^\d{10}$/.test(value);
        setVoterNIN(value);
      };
    const handleVoterNIN = async (e) => {
        e.preventDefault();
        if (national_id === '' ) {
            setEmptyFieldNIDA(true)
        } else {
        if (addShowForm1) setAddShowForm1(false)
        if (!addShowForm1) setAddShowForm1(true)
        if (addShowForm2) setAddShowForm2(false)
        if (!addShowForm2) setAddShowForm2(true)
        const nida_no = national_id.replace(/[^\d]/g, '');

      
        await getVoterNIN({
            variables: {national_id: nida_no},
        });
    }
        // setVoterNIN("");

    };


    // const handleVoterNIN = async (e) => {
    //     if (addShowForm1) setAddShowForm1(false)
    //     if (!addShowForm1) setAddShowForm1(true)
    //     if (addShowForm2) setAddShowForm2(false)
    //     if (!addShowForm2) setAddShowForm2(true)
    //     const nida_no = national_id.replace(/[^\d]/g, '');


    //     e.preventDefault();
    //     await getVoterNIN({
    //         variables: {national_id: nida_no},
    //     });
    //     // setVoterNIN("");

    // };
// useEffect(()=>{
//     if(dataqf){
//       setnewward(dataqf.voterPersonalInfo.registrationcenter.village_mtaa.ward.wardid)
//       setnewmtaa(dataqf.voterPersonalInfo.registrationcenter.village_mtaa.villageid)

//     }

//   },dataqf)

    const handleVoterPN = async (e) => {
        const registration_category_id = 3;
        const r_ward_id = ward;
        const rvillage_id = mtaa;
        if (addShowForm2) setAddShowForm2(false)
        // if (!addShowForm2) setAddShowForm2(true)

        const unvoter = voterid.replace(/[^\d]/g, '');
        const dob = birthYear.replace(/[^\d]/g, '');
        const phonetz = contact_mobile_no.replace(/[^\d]/g, '');
        const nida_no = national_id.replace(/[^\d]/g, '');

        e.preventDefault();
        await SendVoterDataz({
            variables: {
                application_reference_no: application_reference_no,
                registration_category_id: registration_category_id,
                contact_mobile_no: phonetz,
                voter_id: unvoter,
                national_id: nida_no,
                registration_ward_id: r_ward_id,
                registration_village_id: rvillage_id
            },
        });
        //setVoterNIN("");

    };


    const onRegionSelected = (e) => {
        let value = parseInt(e.target.value)
        setCHR(value)

        const regionCouncils = getCouncils.data.coucils.filter(c => c.region_id === value);

        setSelectedCouncils(regionCouncils)
        // alert(JSON.stringify(getCouncils.data.coucils));
    }


    const onCouncilSelected = (e) => {
        let value = parseInt(e.target.value)
        setCHCO(value)
        getWardCouncil({variables: {council_id: value}})
    };

    useEffect(() => {
        if (dataqz) {
            setselectedWard(dataqz.wardByCouncilID)

        }
        if (datamtaa) {
            setselectedMtaa(datamtaa.villageByWardID)
        }

    }, [dataqz, datamtaa])

    const onWardSelected = (e) => {
        let value = parseInt(e.target.value)
        setCHWA(value)
        getMtaaWard({variables: {council_id: value}})
    };

    const onMtaaSelected = (e) => {
        let value = parseInt(e.target.value)
        setCHMTAA(value)
        //getMtaaWard({variables: {councilid :value }})
    };


    const showAlert = (alert) => {
        if (hideModal) sethideModal(false)
        if (alert == 'successvoter') {
            Swal({
                title: intl.formatMessage({id: localizationKeys.general.ndugumpigakura}),
                text: intl.formatMessage({id: localizationKeys.general.ombilimekamilika}),
                icon: "success",
                //buttons: true,
                buttons: intl.formatMessage({id: localizationKeys.general.funga}),


            })
                .then((willDelete) => {
                    if (willDelete) {

                        window.location.reload();

                    } else {
                        Swal("!");
                    }
                });

        } else if (alert == 'sendvoterfail') {

            Swal({
                title: intl.formatMessage({id: localizationKeys.general.ndugumpigakura}),
                text: intl.formatMessage({id: localizationKeys.general.ombihalijakamilika}),
                icon: "warning",
                //buttons: true,
                buttons: intl.formatMessage({id: localizationKeys.general.funga}),


            })
                .then((willDelete) => {
                    if (willDelete) {

                        window.location.reload();

                    } else {
                        // Swal("!");
                        window.location.reload();
                    }
                });


        } else if (alert == 'voternotfount') {

            Swal({
                title: intl.formatMessage({id: localizationKeys.general.ndugumpigakura}),
                text: intl.formatMessage({id: localizationKeys.general.taarifahazipo}),
                icon: "warning",
                //buttons: true,
                buttons: intl.formatMessage({id: localizationKeys.general.funga}),


            })
                .then((willDelete) => {
                    if (willDelete) {

                        window.location.reload();

                    } else {
                        // Swal("!");
                        window.location.reload();
                    }
                });
        }
    };

    const handleCloseModalClick = () => {
        setEmptyField(false);
    }

    return (
        <div>

            <ol style={{marginLeft: "-10px"}}>
                <li> {intl.formatMessage({id: localizationKeys.general.landing_page4})}
                    <ol>
                        <li>  {intl.formatMessage({id: localizationKeys.general.landing_page4a})} </li>
                        <li>{intl.formatMessage({id: localizationKeys.general.landing_page4b})}</li>
                        <li>   {intl.formatMessage({id: localizationKeys.general.landing_page4c})}</li>
                        <li>   {intl.formatMessage({id: localizationKeys.general.landing_page4d})}</li>
                    </ol>
                </li>
                <li>  {intl.formatMessage({id: localizationKeys.general.landing_page4e})} </li>
            </ol>
            {/* <center style={{marginTop: "-1%"}}> */}
           {/*  <center style={{marginTop: "8%"}}>

                <b>  {intl.formatMessage({id: localizationKeys.general.bonyeza})}  &#x21B4; </b></center> */}
            <center>
                {/* <button  style={{backgroundColor: "#fd7e14", width: '60%'}}
                        type="button"
                        className="btn  btn-lg "
                        data-bs-toggle="modal"
                        data-bs-target="#searchVyoteInfoModalLabel"
                >
                    <div className="d-flex align-items-left" style={{
                        'color': '#fff',
                        'font-size': '15px',
                        'font-weight': '1200',
                        'font-family': 'Book Antique'
                    }}>
                        <FaUser className="icon"/>
                        <div><b>{intl.formatMessage({id: localizationKeys.general.BtnVyote})} </b></div>
                    </div>
                </button> */}
                {/* <button class="btn btn--block card__btn"> {intl.formatMessage({id: localizationKeys.general.BtnVyote})}</button> */}
            </center>


            {hideModal ?

                <div
                    className="modal fade"
                    id="searchVyoteInfoModalLabel"
                    data-bs-backdrop="static" data-bs-keyboard="false"
                    tabIndex="-1"
                    aria-labelledby="searchVyoteInfoModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-lg"  style={{marginTop:"10%"}}>
                        {/* <div className="modal-content  p-3 border border-2 rounded-3 border-dark has-background-light  "> */}
                        <div className="modal-content border-0">

                            <div className="modal-header">
                                <h5 className="modal-title" id="searchVyoteInfoModalLabel">
                                    {intl.formatMessage({id: localizationKeys.general.fomubadilivyote})}
                                </h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    onClick={handleCloseModalClick}
                                ></button>

                                {/* <button
              type="button" 
               
               className="btn-close" 
               data-bs-dismiss="modal" 
               aria-hidden="true">&times;</button> */}

                            </div>

                            {addShowForm ?
                                <form onSubmit={handleVoterSearch}>
                                    <div className="modal-body">
                                        <div className="form-floating mb-3">
                                            <InputMask
                                                mask='T 9999 9999 999 9'
                                                type="text"
                                                name="voterid"
                                                value={voterid}
                                                onChange={(e) => setVoterId(e.target.value)}
                                                className="form-control"
                                                id="floatingInput"
                                                placeholder="Enter Voter ID"
                                                autoComplete="none"
                                            />
                                             {emptyFieldVoter === true ? (
                                                <span style={{color: "red"}}> {intl.formatMessage({id: localizationKeys.general.JazaVoter})} </span>) : null}
                                                {emptyFieldVoter12 === true ? (
                                                <span style={{color: "red"}}>Namba ya mpiga kura Hazijatimia</span>) : null}
                                            
                                            <label
                                                htmlFor="floatingInput">{intl.formatMessage({id: localizationKeys.general.nambampigakura})}</label>
                                        </div>
                                        <div className="form-floating mb-3">
                                            <InputMask
                                                mask='9999'
                                                type="text"
                                                name="birthYear"
                                                value={birthYear}
                                                onChange={(e) => setBirthYear(e.target.value)}
                                                className="form-control"
                                                id="floatingInput"
                                                placeholder="Enter Your Year Of Birth"
                                                autoComplete="none"
                                            />
                                             {emptyFieldAge === true ? (
                                                <span style={{color: "red"}}> {intl.formatMessage({id: localizationKeys.general.JazaAge})} </span>) : null}
                                               
                                            
                                            <label
                                                htmlFor="floatingInput">{intl.formatMessage({id: localizationKeys.general.mwakampigakura})}</label>
                                         
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button style={{
                                            'background-color': '#fd7e14',
                                            'color': '#fff',
                                            'font-family': 'Varela Round'
                                        }}
                                                className="w-100 btn btn-lg mb-2" type="submit">
                                            {intl.formatMessage({id: localizationKeys.general.endelea})}
                                        </button>
                                    </div>
                                </form>

                                : null
                            }
                            {loadingqf && (
                                <div className="d-flex justify-content-center">
                                    <Spinner/>
                                </div>
                            )}

                            {errorqf && (
                                <p className="alert alert-danger m-3">
                                    {/* Error occurred: {errorqf.message} */}
                                    {showAlert('voternotfount')}
                                     {/* Error ya mpiga kura . */}
                                </p>
                            )}
                            {dataqf && dataqf.voterPersonalInfo ? (
                                <>
                                    <div
                                        className="d-flex justify-content-flex-start m-3  p-3 border rounded-3 border-dark-1 has-background-light">
                                        <p>
                                            <h6>
                                                <b> {intl.formatMessage({id: localizationKeys.general.Taarifa_binafsi})} </b>
                                            </h6>

                                            <b>  {intl.formatMessage({id: localizationKeys.general.jinakamili})}: </b>
                                            <span>{dataqf.voterPersonalInfo.firstname} {dataqf.voterPersonalInfo.middlename} {dataqf.voterPersonalInfo.surname}</span>
                                            <br/>

                                            <b> {intl.formatMessage({id: localizationKeys.general.jins})}: </b>
                                            <span>{dataqf.voterPersonalInfo.sex} </span>

                                        </p>
                                        {/*<p>
                    BirthDate:{" "}
                    <span>{moment(dataqf?.voter.birthdate).format("LL")}</span>
                  </p>*/}
                                    </div>
                                    <div>
                                        {addShowForm1 ?
                                            <form onSubmit={handleVoterNIN}>
                                                <div className="modal-body">
                                                    <div className="form-floating mb-3">
                                                        <InputMask
                                                            mask='99999999 99999 99999 99'
                                                            type="text"
                                                            name="national_id"
                                                            value={national_id}
                                                            onChange={(e) => setVoterNIN(e.target.value)}
                                                            className="form-control"
                                                            id="floatingInput"
                                                            placeholder="NIDA NUMBER"
                                                            autoComplete="none"
                                                            // required
                                                        />
                                                         {emptyFieldNIDA === true ? (
                                                <span style={{color: "red"}}> {intl.formatMessage({id: localizationKeys.general.JazaNIDA})} </span>) : null}
                                                        <label
                                                            htmlFor="floatingInput">{intl.formatMessage({id: localizationKeys.general.nambayanida})}</label>
                                                    </div>
                                                </div>
                                                <div className="modal-footer">
                                                    <button style={{
                                                        'background-color': '#fd7e14',
                                                        'color': '#fff',
                                                        'font-family': 'Varela Round'
                                                    }}
                                                            className="w-100 btn btn-lg " type="submit">
                                                        {intl.formatMessage({id: localizationKeys.general.endelea})}
                                                    </button>
                                                </div>
                                            </form>

                                            : null
                                        }

                                        {loadingqt && (
                                            <div className="d-flex justify-content-center">
                                                <Spinner/>
                                            </div>
                                        )}

                                        {errorqt && (
                                            <p className="alert alert-danger m-3">
                                                {/* Error occurred: {errorqt.message} */}
                                                {showAlert('voternotfount')}
                                                {/* Hazijapatikana taarifa zako za NIDA.*/}

                                            </p>
                                        )}
                                        {dataqt && dataqt.nida ? (
                                            <>
                                                <div
                                                    className="d-flex justify-content-flex-start m-3  p-3 border rounded-3 border-dark-1 has-background-light">
                                                    <p>
                                                        {/*NIDA ID: <span>{dataqt.nida.national_id}</span> */}
                                                        <h6>
                                                            <b> {intl.formatMessage({id: localizationKeys.general.zinazoboreshwa})} </b>
                                                        </h6>

                                                        <b> {intl.formatMessage({id: localizationKeys.general.jinakamili})} : </b>
                                                        <span>{dataqt.nida.firstname}  {dataqt.nida.middlename} {dataqt.nida.surname}</span>
                                                        <br/>
                                                       {/* <b> {intl.formatMessage({id: localizationKeys.general.jins})}: </b>
                                                        <span>{dataqt.nida.sex.sex_abbrv_sw} </span>*/}
                                                    </p>


                                                </div>
                                            </>
                                        ) : dataqt && !loadingqt ? (
                                            <p className="alert alert-danger m-3">
                                                {showAlert('voternotfount')}

                                                {intl.formatMessage({id: localizationKeys.general.taarifahazipo})}
                                            </p>
                                        ) : null}


                                    </div>


                                    {addShowForm2 && dataqt && dataqt.nida ?

                                        <form onSubmit={handleVoterPN}>
                                            <div className="modal-body">
                                                <div className="form-floating mb-3">


                                                    <InputMask
                                                        mask='255 999 999 999 '
                                                        name="contact_mobile_no"
                                                        value={contact_mobile_no}
                                                        onChange={(e) => setVoterPN(e.target.value)}
                                                        className="form-control"
                                                        id="floatingInput"
                                                        placeholder="Year Of Birth"
                                                        autoComplete="none"
                                                        required
                                                    />
                                                    <label
                                                        htmlFor="floatingInput">{intl.formatMessage({id: localizationKeys.general.namba})}</label>
                                                </div>


                                                <center><h6>
                                                    <b>{intl.formatMessage({id: localizationKeys.general.kituokipya})}  </b>
                                                </h6></center>


                                                <select className="form-control" name="region"
                                                        onChange={onRegionSelected}>
                                                    <option> {intl.formatMessage({id: localizationKeys.general.chaguamkoa})}   </option>
                                                    {/* {getRegions?.data?.regions.map(region => ( */}
                                                    {getRegions?.data?.regionsByRouteDate?.map(region => (

                                                            <option key={region.region_id} value={region.region_id}>
                                                                {region.region}
                                                            </option>
                                                        )
                                                    )
                                                    }
                                                </select>


                                                <div style={{"margin-top": "12px"}}>
                                                    {selectedCouncils.length > 0 ? (
                                                        <select className="form-control" name="council"
                                                                onChange={onCouncilSelected}>
                                                            <option> {intl.formatMessage({id: localizationKeys.general.chaguahalmashauri})}   </option>
                                                            {selectedCouncils.map(council => (

                                                                <option key={council.council_id}
                                                                        value={council.council_id}>
                                                                    {council.council}
                                                                </option>


                                                            ))}
                                                        </select>
                                                    ) : null}
                                                </div>

                                                <div style={{"margin-top": "12px"}}>
                                                    {loadingqz && <p>Loading....</p>}
                                                    {errorqz && <p>Error:{errorqz.message}</p>}
                                                    {selectedWard.length > 0 ? (
                                                        <select className="form-control" name="ward"
                                                                onChange={onWardSelected}>
                                                            <option> {intl.formatMessage({id: localizationKeys.general.chaguakata})}  </option>
                                                            {selectedWard.map(ward => (
                                                                <option key={ward.ward_id} value={ward.ward_id}>
                                                                    {ward.ward}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    ) : null}
                                                </div>

                                                {/* <div style={{"margin-top":"12px"}}>
{loadingmtaa && <p>Loading....</p>}
 {errormtaa && <p>Error:{errormtaa.message}</p>}
{selectedMtaa.length>0?(
 <select className="form-control" name="mtaa"  onChange={onMtaaSelected}>
  <option > {intl.formatMessage({ id: localizationKeys.general.chaguamtaa })}  </option>
 {selectedMtaa.map(ward=>(
<option key={ward.village_id} value={ward.village_id}>
          {ward.village}
          </option>
  ))}
 </select>
  ): null }
 </div>  */}


                                            </div>


                                            <div className="modal-footer">
                                                <button style={{
                                                    'background-color': '#fd7e14',
                                                    'color': '#fff',
                                                    'font-family': 'Varela Round'
                                                }}
                                                        className="w-100 btn btn-lg "
                                                        type="submit"
                                                >
                                                    {intl.formatMessage({id: localizationKeys.general.tuma})}
                                                </button>
                                            </div>
                                        </form>

                                        : null
                                    }


                                    {loadingsn && (
                                        <div className="d-flex justify-content-center">
                                            <Spinner/>
                                        </div>
                                    )}

                                    {/* {errorqt && ( */}
                                    {errorsn && (
                                        <p className="alert alert-danger m-3">
                                            {/* Error occurred: {errorsn.message} */}
                                            {showAlert('voternotfount')}
                                        </p>
                                    )}
                                    {datasn && datasn.createOnlineApplication ? (
                                        <>
                                            {showAlert('successvoter')}

                                            <div
                                                className="d-flex justify-content-evenly m-3  p-3 has-background-light">
                                                <p><b> <i>
                                                    {intl.formatMessage({id: localizationKeys.general.ombilimekamilika})}
                                                </i> </b></p>

                                            </div>
                                        </>
                                    ) : datasn && !loadingsn ? (
                                        <p className="alert alert-danger m-3">
                                            {showAlert('sendvoterfail')}

                                            {intl.formatMessage({id: localizationKeys.general.ombihalijakamilika})}
                                        </p>
                                    ) : null}


                                </>
                            ) : dataqf && !loadingqf ? (
                                <p className="alert alert-danger m-3">

                                    {showAlert('voternotfount')}
                                    {intl.formatMessage({id: localizationKeys.general.ombihalijakamilika})}
                                </p>
                            ) : null}
                        </div>
                    </div>
                </div>
                : null} {/*modal hide  */}
        </div>
    );
};


export default SearchVyoteInfoModal;
