import React from "react";
import Nav from "./Nav";
import Footer from "./Footer";

const Wrapper = ({ children }) => {
  return (
    <div style={{'width':'100%'}}>
      <Nav />
      <div style={{marginBottom:"100px"}}>{children}</div>
      <Footer />
    </div>
  );
};

export default Wrapper;
