import React from "react";
// import useScript from 'hooks/useScript';
// import { useScript } from "@uidotdev/usehooks";
import {Helmet} from "react-helmet";
import Safe from "react-safe";

import {useIntl} from "react-intl";
import {localizationKeys} from "../i18n/keys";
import {Card} from "react-bootstrap";
// import "./SearchCategories.css";
import "./Content.css";

import SearchPersonalInfoModal from "./SearchPersonalInfoModal";
import SearchRegistrationCenterModal from "./SearchRegistrationCenterModal";
import SearchVyoteInfoModal from "./SearchVyoteInfoModal";


function SearchCategories() {
    // const MyComponent = props => {
    //     useScript('./script.js');
      
    //     // rest of your component
    //   }
   

      
      

    const intl = useIntl(); //kubadilisha lugha


    
    return (
        

        <div className="row">
            <div className="col-sm-1">
                {''}
            </div>
            <div className="col-sm-10">
        
              
            <div className="row" style={{marginBottom:"10px"}}>
                            {intl.formatMessage({id: localizationKeys.general.landing_page1})} <br/>
                        </div>
           



                        <ul class="cards">
  <li class="cards__item">
    <div class="card">
      {/* <div class="card__image card__image--fence"></div> */}
      <div class="card__content">
        <div class="card__title"> <b> {intl.formatMessage({id: localizationKeys.general.binafsi})} </b>  </div>
        <p class="card__text"> <br /> <SearchPersonalInfoModal /> </p>
        {/* <button class="btn btn--block card__btn">Button</button> */}
        <center style={{marginTop:"1%"}}><b>  {intl.formatMessage({id: localizationKeys.general.bonyeza})}  &#x21B4; <br/> </b></center>
<button style={{backgroundColor: '#fd7e14'}} data-bs-toggle="modal" data-bs-target="#searchPersonalInfoModal" class="btn btn--block card__btn"> {intl.formatMessage({id: localizationKeys.general.BtnBinafsi})}</button>
      </div>
    </div>
  </li>
  <li class="cards__item">
    <div class="card">
      {/* <div class="card__image card__image--river"></div> */}
      <div class="card__content">
        <div class="card__title border-bottom-5"> <b>{intl.formatMessage({id: localizationKeys.general.kituo})} </b> </div>
        
        <p class="card__text"> <br ></br> <br /> <br /> <SearchRegistrationCenterModal /></p>
        {/* <button class="btn btn--block card__btn">Button</button> */}
        <center style={{marginTop:"1%"}}><b>  {intl.formatMessage({id: localizationKeys.general.bonyeza})}  &#x21B4; <br/> </b></center>

<button style={{backgroundColor: '#fd7e14'}} data-bs-toggle="modal" data-bs-target="#registrationCenterModal" class="btn btn--block card__btn"> {intl.formatMessage({id: localizationKeys.general.BtnKuhama})}</button>
      </div>
    </div>
  </li>
  <li class="cards__item">
    <div class="card">
      {/* <div class="card__image card__image--record"></div> */}
      <div class="card__content">
        <div class="card__title"> <b>{intl.formatMessage({id: localizationKeys.general.vyote})} </b> </div>
        <p class="card__text"> <br /> <SearchVyoteInfoModal /></p>
        {/* <button class="btn btn--block card__btn">Button</button> */} 
        <center style={{marginTop:"1%"}}><b>  {intl.formatMessage({id: localizationKeys.general.bonyeza})}  &#x21B4; <br/> </b></center>

        <button style={{backgroundColor: '#fd7e14'}} data-bs-toggle="modal" data-bs-target="#searchVyoteInfoModalLabel"  class="btn btn--block card__btn"> {intl.formatMessage({id: localizationKeys.general.BtnVyote})}</button>

      </div>
    </div>
  </li>

</ul>


            
                     


                {/* <Card>
                    <Card.Body>
                        <div className="row" style={{marginBottom:"10px"}}>
                            {intl.formatMessage({id: localizationKeys.general.landing_page1})} <br/>
                        </div>
                        <div className="row">
                            <div className="col-sm-4">
                                <Card.Header as="h5" style={{backgroundColor:"#edeef0",width:"471px",marginLeft:"-1px"}}><center>{intl.formatMessage({id: localizationKeys.general.binafsi})}  </center></Card.Header>
                                <div className="content-container" style={{width:"100%"}}>
                                    <SearchPersonalInfoModal />
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <Card.Header as="h5" style={{backgroundColor:"#edeef0",width:"471px",marginLeft:"-1px"}}><center>{intl.formatMessage({ id: localizationKeys.general.kituo })}  </center></Card.Header>
                                <div className="content-container" style={{width:"100%"}}>
                                
                                    <SearchRegistrationCenterModal />
                                </div>
                            </div>

                            <div className="col-sm-4">
                                <Card.Header as="h5" style={{backgroundColor:"#edeef0",width:"471px",marginLeft:"-1px"}}><center>{intl.formatMessage({ id: localizationKeys.general.vyote })} </center></Card.Header>
                                <div className="content-container" style={{width:"100%"}}>
                                    <SearchVyoteInfoModal />
                                </div>
                            </div>
                        </div>
                    </Card.Body>
                </Card> */}
            </div>
            <div className="col-sm-1">
                {''}
            </div>
        </div>


    );
 }


export default SearchCategories;
