export const general = {
  jamuhuri: "JAMHURI YA MUUNGANO WA TANZANIA",
  tume:"TUME HURU YA TAIFA YA UCHAGUZI",
  // mfumo : "MFUMO WA KUBORESHA TAARIFA ZA MPIGA KURA KWA NJIA YA MTANDAO",
    mfumo : "Mfumo wa kuanzisha mchakato wa kuboresha taarifa za mpiga kura",
  binafsi: " KUREKEBISHA AU KUBADILI TAARIFA BINAFSI",
  // binafsi: " KUBORESHA TAARIFA BINAFSI  ",

  // kituo: "KUHAMA KITUO",
   kituo: "KUHAMA KITUO",
  landing_page111: "Ndugu Mpiga Kura, anzisha mchakato wa awali wa kuboresha taarifa za mpiga kura kwa njia ya mtandao na baada ya kukamilisha hatua hii inapaswa kufika katika kituo cha kujiandikisha kwa ajili ya kumalizia mchakato wa kuchukua picha na saini  na kisha kupatiwa kadi ya mpiga kura. Uboreshaji wa taarifa utahusisha kurekebisha au kubadilisha taarifa binafsi, kuhama kituo au kurekebisha au kubadilisha taarifa binafsi na kuhama kwa kubonyeza kitufe husika; ",

  landing_page1: "Ndugu mpiga kura, anzisha mchakato wa awali wa kuboresha taarifa za mpiga kura kwa njia ya mtandao.Baada ya kukamilisha hatua hii utapaswa kufika katika kituo cha kujiandikisha ndani ya kata husika kwa ajili ya kumalizia mchakato na kupewa kadi ya mpiga kura. Uboreshaji wa taarifa utahusisha kurekebisha au kubadili taarifa binafsi, kuhama kituo na kurekebisha au kubadili taarifa binafsi na kuhama kituo kwa kubonyeza kitufe husika.",

  // landing_page2: " 1 .  Kuboresha taarifa zake binafsi  Mpiga Kura atapaswa kuwa na Namba ya Mpiga (Inayopatikana kwenye Kadi ya Mpiga Kura)  Kura pamoja na Namba ya Kitambulisho cha Taifa",
  // landing_page3: "2.  Kuhamisha taarifa zake kutoka kituo kimoja kwenda mahali pengine. Hapa atapaswa kuwa na Namba ya Mpiga Kura na mwaka wake wa Kuzaliwa ",
  // landing_page4:  "3.  Au Kuboresha taarifa zake binafsi na Kuhamisha taarifa zake za Kituo kutoka kituo cha awali kwenda mahali pengine.",

  landing_page2: " 1. Kurekebisha au kubadili taarifa binafsi, mpiga kura atapaswa kufanya yafuatayo: - ",
  landing_page2a: " i. kujaza namba ya mpiga kura;",
  landing_page2b: " ii. kujaza mwaka wa kuzaliwa; ",
  landing_page2c: " iii.kujaza namba ya kitambulisho cha Taifa (NIDA); na",
  landing_page2d: " iv. kujaza namba ya simu inayotumika kwa sasa.",
  landing_page2e: " 2. Taarifa zitakazopatikana baada ya kujaza namba ya kitambulisho cha Taifa  (NIDA) ndizo zitakazotumika kwenye Daftari la Kudumu la Wapiga Kura.",

  landing_page3: "1. Kuhamisha taarifa za mpiga kura  kutoka eneo moja la kiuchaguzi kwenda eneo lingine, mpiga kura atapaswa kufanya yafuatayo: - ",
  landing_page3a: " i. kujaza namba ya mpiga kura ;",
  landing_page3b: " ii. kujaza mwaka wa kuzaliwa; ",
  landing_page3c: " iii. kujaza namba ya simu inayotumika kwa sasa; na",
  landing_page3d: " iv. kuchagua kata anayotaka kuhamia. ",
  landing_page3e: " 2. Mpiga kura atachagua kata anayotaka kuhamia na itampasa kufika kwenye kituo cha kata husika ili kukamilisha mchakato na kupewa kadi.",

  landing_page4:  "1. Kurekebisha au kubadili taarifa binafsi na kuhamisha taarifa kutoka kituo cha awali kwenda kituo kingine, mpiga kura anapaswa kufanya yafuatayo: -",
  landing_page4a: " i. kujaza namba ya mpiga kura;",
  landing_page4b: " ii. kujaza mwaka wa kuzaliwa;",
  landing_page4c: " iii. kujaza namba ya kitambulisho cha Taifa (NIDA); na",
  landing_page4d: " iv. kujaza namba ya simu inayotumika kwa sasa. ",
  landing_page4e: " 2. Taarifa zitakazopatikana baada ya kujaza namba ya kitambulisho cha Taifa (NIDA) ndizo zitakazotumika kwenye Daftari la Kudumu la Wapiga Kura.",

  landing_page5:" Maelezo;",
  kujihakiki: "ilikuhakiki taarifa zako kama zipo sahihi ",
  bonyeza:"Bofya hapo chini  ",
  vyote: " KUREKEBISHA AU KUBADILI TAARIFA BINAFSI NA KUHAMA KITUO",
  //vyote: " KUBORESHA NA  KUHAMA",
  fomutaarifabinafsi:" FOMU YA TAARIFA BINAFSI",
  nambampigakura:" Andika namba ya mpiga kura",
  mwakampigakura:" Andika mwaka wa kuzaliwa",
  endelea :"Endelea",
  jinakamili : "JINA KAMILI",
  nambayanida : "Andika namba ya kitambulisho cha Taifa (NIDA)",
  namba : "Andika namba ya simu inayotumika sasa.",
  tuma : "Tuma",
  ombilimekamilika:" Ombi lako limepokelewa na utapokea ujumbe mfupi kupitia simu yako ya mkononi.",
  ombihalijakamilika : "  Ombi lako limeshindikana Kutuma ",
  // taarifahazipo : "Hazijapatikana Taarifa zako wasiliana nasi kupata taarifa kamili . Asante",
  taarifahazipo : "Taarifa zako hazijapatikana, tafadhali ingiza namba ya Mpiga Kura  au fika kwenye kituo chako kwa ajili ya kuendelea na uboreshaji.",
  fomubadilikituo: "FOMU YA KUHAMA KITUO",
  mkoa: "MKOA",
  halmashauri: "HALMASHAURI",
  jimbo: "JIMBO",
  kata: "KATA/SHEHIA",
  mtaa :" KIJIJI/MTAA",
  kituoni :"KITUO",
  kituosasa:"TAARIFA ZA KITUO CHA SASA CHA MPIGA KURA ",
  // kituokipya:"PENDEKEZO LA KATA MPYA YA MPIGA KURA ",
    kituokipya:" KUCHAGUA  KATA UNAYOTAKA KUHAMIA",

  chaguamkoa: "CHAGUA MKOA",
  chaguahalmashauri: "CHAGUA Halmashauri",
  chaguajimbo: "CHAGUA JIMBO",
  chaguakata: "CHAGUA KATA/SHEHIA",
  chaguamtaa :" CHAGUA KIJIJI/MTAA",
  chaguakituoni :"CHAGUA KITUO",
  fomubadilivyote:"FOMU YA KUBORESHA NA KUHAMA",
  ndugumpigakura :"Ndugu Mpiga Kura,",
  funga :"Funga",
  jins :"JINSI",
  Taarifa_binafsi :"TAARIFA BINAFSI ZA MPIGA KURA",
  zinazoboreshwa :"TAARIFA  ZINAZOPENDEKEZWA KUBORESHWA",

  Jazavoter :"TAFADHALI JAZA NAMBA YA MPIGA KURA",
  JazaNIDA :"TAFADHALI JAZA NAMBA YA NIDA",
  JazaAge :"TAFADHALI JAZA MWAKA  ",
  JazaPhone :"TAFADHALI JAZA NAMBA YA SIMU  ",
  // BtnBinafsi :"BORESHA TAARIFA BINAFSI  ",
  // BtnKuhama :"KUHAMA KITUO ",
  // BtnVyote :"KUBORESHA NA KUHAMA KITUO ",
  BtnBinafsi :"Boresha Taarifa Binafsi  ",
  BtnKuhama :"Kuhama Kituo ",
  BtnVyote :"Kuboresha na Kuhama Kituo ",




};
