import React from "react";
 import Home from "./components/Home";
// import Home from "./layout/header/Header";

const App = () => {
  return <div>
    <Home/>
  </div>;
};

export default App;
