export const general = {
  binafsi: "general.binafsi",
  kituo: "general.kituo",
  vyote: "general.vyote",
  landing_page1 : "general.landing_page1",
  landing_page2 : "general.landing_page2",
  landing_page2a : "general.landing_page2a",
  landing_page2b : "general.landing_page2b",
  landing_page2c : "general.landing_page2c",
  landing_page2d : "general.landing_page2d",
  landing_page2e : "general.landing_page2e",
  landing_page3 : "general.landing_page3",
  landing_page3a : "general.landing_page3a",
  landing_page3b : "general.landing_page3b",
  landing_page3c : "general.landing_page3c",
  landing_page3d : "general.landing_page3d",
  landing_page3e : "general.landing_page3e",

  landing_page4 : "general.landing_page4",
  landing_page4a : "general.landing_page4a",
  landing_page4b : "general.landing_page4b",
  landing_page4c : "general.landing_page4c",
  landing_page4d : "general.landing_page4d",
  landing_page4e : "general.landing_page4e",

  landing_page5 : "general.landing_page5",
  nambampigakura :"general.nambampigakura",
  mwakampigakura :"general.mwakampigakura",
  fomutaarifabinafsi:"general.fomutaarifabinafsi",
  endelea:"general.endelea",
  jinakamili:"general.jinakamili",
  nambayanida :"general.nambayanida",
  namba :"general.namba",
  tuma :"general.tuma",
  ombilimekamilika :"general.ombilimekamilika",
  ombihalijakamilika :"general.ombihalijakamilika",
  taarifahazipo  :"general.taarifahazipo",
  fomubadilikituo :"general.fomubadilikituo",
  mkoa: "general.mkoa",
  halmashauri: "general.halmashauri",
  jimbo: "general.jimbo",
  kata: "general.kata",
  mtaa :"general.mtaa",
  kituoni :"general.kituoni",
  kituosasa:"general.kituosasa",
  kituokipya:"general.kituokipya",
  chaguamkoa: "general.chaguamkoa",
  chaguahalmashauri: "general.chaguahalmashauri",
  chaguajimbo: "general.chaguajimbo",
  chaguakata: "general.chaguakata",
  chaguamtaa :"general.chaguamtaa",
  chaguakituoni :"general.chaguakituoni",
  fomubadilivyote :"general.fomubadilivyote",
  jamuhuri :"general.jamuhuri",
  tume :"general.tume",
  mfumo :"general.mfumo",
  ndugumpigakura :"general.ndugumpigakura",
  funga :"general.funga",
  kujihakiki:"general.kujihakiki",
  bonyeza:"general.bonyeza",
  jins:"general.jins",
  zinazoboreshwa:"general.zinazoboreshwa",
  Taarifa_binafsi:"general.Taarifa_binafsi",
  JazaVoter:"general.Jazavoter",
  JazaNIDA:"general.JazaNIDA",
  JazaAge:"general.JazaAge",
  JazaPhone:"general.JazaPhone",
  BtnVyote:"general.BtnVyote",
  BtnBinafsi:"general.BtnBinafsi",
  BtnKuhama:"general.BtnKuhama",

};
export const localizationKeys = {
  general: general,
};
