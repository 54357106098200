import {FaMapMarkerAlt} from "react-icons/fa";
import React, {useEffect, useState} from "react";
import {useLazyQuery, useMutation, useQuery} from "@apollo/client";
import {
    GET_CONS,
    GET_COUCILS,
    GET_KATA_CONS,
    GET_MTAA_KATA,
    GET_REGIONS,
    GET_VOTER,
    GET_WARD,
    SendVoterData1
} from "../Queries/VotersQueries.js";
import Spinner from "./Spinner";
import nextId from "react-id-generator";
import InputMask from 'react-input-mask';

//kubadili lugha
import {localizationKeys} from "../i18n/keys";
import {useIntl} from "react-intl";
//sweetlert pop up
import Swal from 'sweetalert';

const SearchRegistrationCenterModal = () => {

    const intl = useIntl(); //kubadilisha lugha
    const [addShowForm, setAddShowForm] = useState(true);
    const [addShowForm2, setAddShowForm2] = useState(true);
    const [voterid, setVoterId] = useState("");
    const [birthYear, setBirthYear] = useState("");
    const [region, setCHR] = useState("");
    const [council, setCHCO] = useState("");
    const [ward, setCHWA] = useState("");
    const [mtaa, setCHMTAA] = useState("");

    const [contact_mobile_no, setSRPN] = useState("");
    const [selectedCouncils, setSelectedCouncils] = useState([]);
    const [selectedConstituencies, setSelectedConstituencies] = useState([]);
    const [selectedWard, setselectedWard] = useState([]);
    const [selectedMtaa, setselectedMtaa] = useState([]);
    const [hideModal, sethideModal] = useState(true); //  hide  modal pop up

    const application_reference_no = nextId("CREG-INFO-");


    const getRegions = useQuery(GET_REGIONS)
    const Rerror = getRegions.error
    const Rloading = getRegions.loading
    const getCouncils = useQuery(GET_COUCILS);
    const getCons = useQuery(GET_CONS);
    const getWard = useQuery(GET_WARD);
    const [getWardCouncil, {loading: loadingqt, error: errorqt, data: dataqt}] = useLazyQuery(GET_KATA_CONS);
    const [getMtaaWard, {loading: loadingmtaa, error: errormtaa, data: datamtaa}] = useLazyQuery(GET_MTAA_KATA);

    const [getVoter, {loading, error, data}] = useLazyQuery(GET_VOTER);
    const [SendVoterDataz, {loading: loadingsn, error: errorsn, data: datasn}] = useMutation(SendVoterData1);
    const [emptyFieldVoter, setEmptyField] = useState(false);
    const [emptyFieldVoter12, setEmptyFieldV12] = useState(false);
    const [emptyFieldAge, setEmptyFieldAge] = useState(false);
    const [emptyFieldNIDA, setEmptyFieldNIDA] = useState(false);

    const handleChange = (e) => {
        const { value } = e.target;
        // const VoterCheck = /^\d{10}$/.test(value);
        setVoterId(value);
      };
    const handleVoterSearch = async (e) => {
        e.preventDefault();
        // if (voterid === '' || birthYear === '') {

       
            if (voterid === '' ) {
            setEmptyField(true)
        }else if (voterid >= 10 ) {
                setEmptyFieldV12(true)
         }else if ( birthYear === '') {
            console.log("hello --> " + voterid); 
            setEmptyField(false)
            setEmptyFieldAge(true)

            if (addShowForm) setAddShowForm(true)
        } else {
            setEmptyField(false)
            setEmptyFieldAge(false)

            if (addShowForm) setAddShowForm(false)
                if (!addShowForm) setAddShowForm(true)
                if (addShowForm2) setAddShowForm2(true)
                if (!addShowForm2) setAddShowForm2(false)
            const unvoter = voterid.replace(/[^\d]/g, '');
            const dob = birthYear.replace(/[^\d]/g, '');
            await getVoter({
                variables: {voter_id: unvoter, birthYear: parseInt(dob)},
                //    variables: { voter_id: 100000000064, birthYear: 1965 },

            });
        }
    };




    // const handleVoterSearch = async (e) => {
    //     if (addShowForm) setAddShowForm(false)
    //     if (!addShowForm) setAddShowForm(true)
    //     if (addShowForm2) setAddShowForm2(true)
    //     if (!addShowForm2) setAddShowForm2(false)
    
    //          const unvoter = voterid.replace(/[^\d]/g, '');
    //        // const unvoter = voterids.replace(/[^\d]/g, '');
    //         const dob = birthYear.replace(/[^\d]/g, '');
    //         // console.log('TEST'+unvoter);
    //         // console.log('TEST1'+voterids);
    
    //     e.preventDefault();
    //     await getVoter({
    //         variables: { voter_id: unvoter, birthYear: parseInt(dob) },
    //         //    variables: { voter_id: 100000000064, birthYear: 1965 },
    
    //     });
    //     //setVoterId("");
    //    // setBirthYear("");
    //   };

    const onRegionSelected = (e) => {
        let value = parseInt(e.target.value)
        setCHR(value)

// let valueData = []
//  getCouncils.data.coucils.map(c=>{
//     if(c.councilid===value){
//       valueData.push({councilid:c.councilid, council:c.council})
//     }
// })

        const regionCouncils = getCouncils.data.coucils.filter(c => c.region_id === value);

        setSelectedCouncils(regionCouncils)
        // alert(JSON.stringify(getCouncils.data.coucils));
    }


    const onCouncilSelected = (e) => {
        let value = parseInt(e.target.value)
        setCHCO(value)
        getWardCouncil({variables: {council_id: value}})
    };

    useEffect(() => {
        if (dataqt) {
            setselectedWard(dataqt.wardByCouncilID)

        }
        if (datamtaa) {
            setselectedMtaa(datamtaa.villageByWardID)
        }

    }, [dataqt, datamtaa])


    const onWardSelected = (e) => {
        let value = parseInt(e.target.value)
        setCHWA(value)
        getMtaaWard({variables: {council_id: value}})
    };


    const onMtaaSelected = (e) => {
        let value = parseInt(e.target.value)
        setCHMTAA(value)
        //getMtaaWard({variables: {councilid :value }})
    };


    const handleVoterPN = async (e) => {
        if (addShowForm2) setAddShowForm2(false)

        const registration_category_id = 2;
        const national_id = '0000';
        // const contact_mobile_no ='25';
        const registration_ward_id = ward;
        const registration_village_id = mtaa;
        const phonetz = contact_mobile_no.replace(/[^\d]/g, '');
        const unvoter = voterid.replace(/[^\d]/g, '');


        e.preventDefault();
        await SendVoterDataz({
            variables: {
                application_reference_no: application_reference_no,
                registration_category_id: registration_category_id,
                contact_mobile_no: phonetz,
                voter_id: unvoter,
                national_id: national_id,
                registration_ward_id: registration_ward_id,

            },
        });


    };


    const showAlert = (alert) => {
        if (hideModal) sethideModal(false)
        if (alert == 'successvoter') {
            Swal({
                title: intl.formatMessage({id: localizationKeys.general.ndugumpigakura}),
                text: intl.formatMessage({id: localizationKeys.general.ombilimekamilika}),
                icon: "success",
                //buttons: true,
                buttons: intl.formatMessage({id: localizationKeys.general.funga}),


            })
                .then((willDelete) => {
                    if (willDelete) {

                        window.location.reload();

                    } else {
                        Swal("!");
                    }
                });

        } else if (alert == 'sendvoterfail') {

            Swal({
                title: intl.formatMessage({id: localizationKeys.general.ndugumpigakura}),
                text: intl.formatMessage({id: localizationKeys.general.ombihalijakamilika}),
                icon: "warning",
                //buttons: true,
                buttons: intl.formatMessage({id: localizationKeys.general.funga}),


            })
                .then((willDelete) => {
                    if (willDelete) {

                        window.location.reload();

                    } else {
                        Swal("!");
                    }
                });


        } else if (alert == 'voternotfount') {

            Swal({
                title: intl.formatMessage({id: localizationKeys.general.ndugumpigakura}),
                text: intl.formatMessage({id: localizationKeys.general.taarifahazipo}),
                icon: "warning",
                //buttons: true,
                buttons: intl.formatMessage({id: localizationKeys.general.funga}),


            })
                .then((willDelete) => {
                    if (willDelete) {

                        window.location.reload();

                    } else {
                        Swal("!");
                    }
                });
        }
    };
    const handleCloseModalClick = ()=>{
        setEmptyField(false);
    }


    return (

        <div>

            <ol style={{marginLeft: "-10px"}}>
                <li> {intl.formatMessage({id: localizationKeys.general.landing_page3})}
                    <ol>
                        <li>  {intl.formatMessage({id: localizationKeys.general.landing_page3a})}</li>
                        <li> {intl.formatMessage({id: localizationKeys.general.landing_page3b})}</li>
                        <li> {intl.formatMessage({id: localizationKeys.general.landing_page3c})}</li>
                        <li> {intl.formatMessage({id: localizationKeys.general.landing_page3d})}</li>
                    </ol>
                </li>
                <li>  {intl.formatMessage({id: localizationKeys.general.landing_page3e})} </li>
            </ol>
           
            {/* <br/> */}
            
              

              
            {/* <center style={{marginTop: "12%"}}> */}
            {/* <center style={{marginTop: "20%"}}>

                <b>  {intl.formatMessage({id: localizationKeys.general.bonyeza})}  &#x21B4; <br/> </b></center> */}
            <center>
                {/* <button style={{backgroundColor: '#fd7e14', width: '50%'}}
                        type="button"
                        className="btn  btn-lg "
                        data-bs-toggle="modal"
                        data-bs-target="#registrationCenterModal"
                >
                    <div className="d-flex align-items-left" style={{
                        'color': '#fff',
                        'font-size': '15px',
                        'font-weight': '1200',
                        'font-family': 'Book Antique'
                    }}>
                        <FaMapMarkerAlt className="icon"/>
                        <div style={{'text-align': 'center'}}>
                            <center><b>  {intl.formatMessage({id: localizationKeys.general.BtnKuhama})} </b></center>
                        </div>
                    </div>
                </button> */}
{/* 
<button data-bs-toggle="modal"
                        data-bs-target="#registrationCenterModal" class="btn btn--block card__btn"> {intl.formatMessage({id: localizationKeys.general.BtnKuhama})}</button> */}

            </center>


            {hideModal ?

                <div
                    className="modal fade"
                    id="registrationCenterModal"
                    tabIndex="-1"
                    aria-labelledby="registrationCenterModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-lg"  style={{marginTop:"10%"}}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="registrationCenterModalLabel">
                                    {intl.formatMessage({id: localizationKeys.general.fomubadilikituo})}
                                </h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    onClick={handleCloseModalClick}
                                ></button>


                            </div>
                            {addShowForm ?
                                <form onSubmit={handleVoterSearch}>
                                    <div className="modal-body">
                                        <div className="form-floating mb-3">
                                            <InputMask
                                                mask='T 9999 9999 999 9'
                                                type="text"
                                                name="voterid"
                                                value={voterid}
                                                onChange={(e) => setVoterId(e.target.value)}
                                                className="form-control"
                                                id="floatingInput"
                                                placeholder="Voter ID"
                                            />
                                              {emptyFieldVoter === true ? (
                                                <span style={{color: "red"}}> {intl.formatMessage({id: localizationKeys.general.JazaVoter})} </span>) : null}
                                                {emptyFieldVoter12 === true ? (
                                                <span style={{color: "red"}}>Namba ya mpiga kura Hazijatimia</span>) : null}
                                            <label
                                                htmlFor="floatingInput">{intl.formatMessage({id: localizationKeys.general.nambampigakura})} </label>
                                        </div>
                                        <div className="form-floating mb-3">
                                            <InputMask
                                                mask='9999'
                                                type="text"
                                                name="birthYear"
                                                value={birthYear}
                                                onChange={(e) => setBirthYear(e.target.value)}
                                                className="form-control"
                                                id="floatingInput"
                                                placeholder="Year Of Birth"
                                            />
                                                {emptyFieldAge === true ? (
                                                <span style={{color: "red"}}> {intl.formatMessage({id: localizationKeys.general.JazaAge})} </span>) : null}
                                            <label
                                                htmlFor="floatingInput">{intl.formatMessage({id: localizationKeys.general.mwakampigakura})} </label>
                                         
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button className="w-100 btn btn-lg " style={{
                                            'background-color': '#fd7e14',
                                            'color': '#fff',
                                            'font-family': 'Varela Round'
                                        }} type="submit">
                                            {intl.formatMessage({id: localizationKeys.general.endelea})}
                                        </button>
                                    </div>
                                </form>

                                : null
                            }


                            {loading && (
                                <div>
                                    <Spinner/>
                                </div>
                            )}
                            {error && <p className="alert alert-danger m-3">
                                {/* Error occurred: {error.message} */}
                                {showAlert('voternotfount')}
                            </p>
                            }
                            {data && data.voterPersonalInfo ? (

                                <>
                                    {/* <div className="d-flex flex-column justify-content-flex-start  m-3  p-3 border rounded-3 border-dark-1 has-background-light"> */}
                                        {/* <div className="d-flex justify-content-flex-start m-3  p-3 border rounded-3 border-dark-1  has-background-light"> */}
                                        <div className=" border rounded-3 border-dark-1  has-background-light">

                                        <div className="d-flex justify-content-flex-start m-3  ">

                                         {/* <center>*/}
                                           {/* <span>{data.voterPersonalInfo.firstname} {data.voterPersonalInfo.middlename} {data.voterPersonalInfo.surname}</span> */}
                                           {/* <h6>
                                                <b> {intl.formatMessage({id: localizationKeys.general.Taarifa_binafsi})} </b>
                                            </h6> */}

                                            <b>  {intl.formatMessage({id: localizationKeys.general.jinakamili})}: </b>
                                             
                                             <span>{data.voterPersonalInfo.firstname} {data.voterPersonalInfo.middlename} {data.voterPersonalInfo.surname}</span> 
                                            <br/>
                                            </div>
                                            <div className="   m-3  text-sm-left " >
                                            <b> {intl.formatMessage({id: localizationKeys.general.jins})}: </b>
                                            <span>{data.voterPersonalInfo.sex} </span>
                                            </div>
                                          {/*</center>*/}

                                        {/* <center> <h6> <b> {intl.formatMessage({ id: localizationKeys.general.kituosasa })} </b> </h6> </center>
                  <p >
                    <b> {intl.formatMessage({ id: localizationKeys.general.mkoa })}: </b> <span>{data.voter.registrationcenter.village_mtaa.ward.constituency.council.region.region}</span>
                  </p>
                  <p>
                   <b> {intl.formatMessage({ id: localizationKeys.general.halmashauri })}:</b> <span>{data.voter.registrationcenter.village_mtaa.ward.constituency.council.council}</span>
                  </p>
                  <p>
                   <b> {intl.formatMessage({ id: localizationKeys.general.jimbo })}:</b> <span>{data.voter.registrationcenter.village_mtaa.ward.constituency.constituency}</span>
                  </p>
                  <p>
                   <b> {intl.formatMessage({ id: localizationKeys.general.kata })}:</b> <span>{data.voter.registrationcenter.village_mtaa.ward.ward}</span>
                  </p>

                  
                  <p>
                   <b> {intl.formatMessage({ id: localizationKeys.general.mtaa })}: </b> <span>{data.voter.registrationcenter.village_mtaa.village}</span>
                  </p>
                  <p>
                   <b> {intl.formatMessage({ id: localizationKeys.general.kituoni })} : </b> <span>{data.voter.registrationcenter.registrationcenter}</span>
                  </p>

    */}  
  </div>
  <div>

                                        {addShowForm2 ?

                                            <form onSubmit={handleVoterPN}>
                                              {/*  <center><h6>
                                                    <b>{intl.formatMessage({id: localizationKeys.general.kituokipya})}  </b>
                                                </h6></center>. */}
                                                <div className="modal-body">
                                                    <div className="form-floating mb-3">


                                                        <InputMask
                                                            mask='255 999 999 999 '

                                                            type="text"
                                                            name="contact_mobile_no"
                                                            value={contact_mobile_no}
                                                            onChange={(e) => setSRPN(e.target.value)}
                                                            className="form-control"
                                                            id="floatingInput"
                                                            placeholder="Nambari ya simu"
                                                            autoComplete="none"
                                                            required
                                                        />
                                                        <label
                                                            htmlFor="floatingInput">{intl.formatMessage({id: localizationKeys.general.namba})}</label>
                                                    </div>
 
                                                    <h6>
                                                    <b>{intl.formatMessage({id: localizationKeys.general.kituokipya})                                                    }      </b>
                                                </h6>
                                                    <select className="form-control" name="region"
                                                            onChange={onRegionSelected} required >
                                                        <option>{intl.formatMessage({id: localizationKeys.general.chaguamkoa})}  </option>
                                                         {/* {getRegions?.data?.regions.map(region => ( */}
                                                        {getRegions?.data?.regionsByRouteDate?.map(region => (

                                                       

                                                                <option key={region.regionid} value={region.region_id}>
                                                                    {region.region}
                                                                </option>
                                                            )
                                                        )
                                                        }
                                                    </select>


                                                    <div style={{"margin-top": "12px"}}>
                                                        {selectedCouncils.length > 0 ? (
                                                            <select className="form-control" name="council"
                                                                    onChange={onCouncilSelected} required>
                                                                <option> {intl.formatMessage({id: localizationKeys.general.chaguahalmashauri})} </option>
                                                                {selectedCouncils.map(council => (

                                                                    <option key={council.councilid}
                                                                            value={council.council_id}>
                                                                        {council.council}
                                                                    </option>


                                                                ))}
                                                            </select>
                                                        ) : null}
                                                    </div>

                                                    <div style={{"margin-top": "12px"}}>
                                                        {loadingqt && <p>Loading....</p>}
                                                        {errorqt && <p>Error:{errorqt.message}</p>}
                                                        {selectedWard.length > 0 ? (
                                                            <select className="form-control" name="ward"
                                                                    onChange={onWardSelected} required>
                                                                <option> {intl.formatMessage({id: localizationKeys.general.chaguakata})} </option>
                                                                {selectedWard.map(ward => (
                                                                    <option key={ward.wardid} value={ward.ward_id}>
                                                                        {ward.ward}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        ) : null}
                                                    </div>


                                                </div>


                                                <div className="modal-footer">
                                                    <button style={{
                                                        'background-color': '#fd7e14',
                                                        'color': '#fff',
                                                        'font-family': 'Varela Round'
                                                    }}
                                                            className="w-100 btn btn-lg "
                                                            type="submit"
                                                    >
                                                        {intl.formatMessage({id: localizationKeys.general.tuma})}
                                                    </button>
                                                </div>
                                            </form>


                                            : null
                                        }


                                        {loadingsn && (
                                            <div className="d-flex justify-content-center">
                                                <Spinner/>
                                            </div>
                                        )}

                                        {errorqt && (
                                            <p className="alert alert-danger m-3">
                                                Error occurred: {errorsn.message}
                                            </p>
                                        )}
                                        {datasn && datasn.createOnlineApplication ? (
                                            <>
                                                {showAlert('successvoter')}
                                                <div
                                                    className="d-flex justify-content-evenly m-3  p-3 has-background-light">
                                                    <p><b> <i>
                                                        {intl.formatMessage({id: localizationKeys.general.ombilimekamilika})}
                                                    </i> </b></p>

                                                </div>
                                            </>
                                        ) : datasn && !loadingsn ? (
                                            <p className="alert alert-danger m-3">
                                                {showAlert('sendvoterfail')}
                                                {intl.formatMessage({id: localizationKeys.general.ombihalijakamilika})}
                                            </p>
                                        ) : null}


                                    </div>


                                </>
                            ) : data && !loading ? (
                                <p className="alert alert-danger m-3">
                                    {showAlert('voternotfount')}
                                    {intl.formatMessage({id: localizationKeys.general.ombihalijakamilika})}</p>
                            ) : null}
                        </div>
                    </div>
                </div>
                : null} {/*modal hide  */}
        </div>
    );
};

export default SearchRegistrationCenterModal;
