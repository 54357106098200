import './Style.scss';
import Emblem from "../assets/emblem.png"
import Logo from"../assets/nec-logo.png"
import { localizationKeys } from "../i18n/keys";
import {useIntl} from "react-intl";
const Nav = () => {
    const intl = useIntl(); //kubadilisha lugha
    return (
        <div>
            <div className="navbar navbar-default navbar-top nav-container row" style={{marginTop: "-10px"}}>
                <div className="col-sm-1">
                    {' '}
                </div>
                <div className="col-sm-10">
                    <table style={{width: "100%",background:"none"}}>
                        <thead>
                        <tr>
                            <td style={{paddingLeft: "0px",width: "20%"}}>
                                <img src={Emblem} alt="Tanzania emblem" className="mx-auto img-fluid"
                                     style={{height: "100px", width: "100px"}}/>
                            </td>
                            <td style={{textAlign: "right",width: "60%"}}>
                                <div className="tr_slog">
                                    {/* <p id="head">Jamhuri ya Muungano wa Tanzania</p> */}
                                    <p id="head" style={{
                                        paddingBottom: "0px",
                                        marginBottom: "2px"
                                    }}>  {intl.formatMessage({id: localizationKeys.general.jamuhuri})} </p>
                                    {/* <p id="body">Tume ya Taifa ya Uchaguzi</p> */}
                                    <p id="body"  style={{
                                        paddingBottom: "0px",
                                        marginBottom: "2px"
                                    }}>  {intl.formatMessage({id: localizationKeys.general.tume})} </p>

                                    <p className="mb-1 title text-uppercase" style={{'margin-top': '1%'}}>
                                        <b> {intl.formatMessage({id: localizationKeys.general.mfumo})} </b></p>
                                </div>
                            </td>
                            <td style={{textAlign: "right", paddingRight: "0px",width: "20%"}}>
                                <img src={Logo} alt="Tanzania emblem" className="mx-auto img-fluid"
                                     style={{height: "100px", width: "100px"}}/>
                            </td>
                        </tr>
                        </thead>
                    </table>
                </div>
                <div className="col-sm-1">
                    {' '}
                </div>
            </div>
        </div>
    )
}

export default Nav;


//import React from "react";
// import Nembo from "../assets/Nembo.png";
// import logo from "../assets/Logo.png";
// import txt from "../assets/txt.png";

// import { localizationKeys } from "../i18n/keys";
// import { useIntl } from "react-intl";
// //import './Header.scss';

// function Nav() {
//   const intl = useIntl(); //kubadilisha lugha
//   return (
//     <nav className="navbar bg-light mb-4 p-0"  style={{ 'width':'100%'}}>
//       <div className="container" >

//       <div class="col-2 col-md-3 float-left text-left my-auto">
//            <img src={Nembo} alt="Tanzania emblem" class="mx-auto img-fluid" width="90" />
//           </div>

//          <div class="col-8 col-md-6 text-center my-auto">
//             <h5 class="mb-2 sub-title text-uppercase">
//               {intl.formatMessage({ id: localizationKeys.general.jamuhuri })}
//             </h5>
//             <h3 class="mb-1 title text-uppercase">
//               {intl.formatMessage({ id: localizationKeys.general.tume })}
//             </h3>


//             <p class="mb-1 title text-uppercase"  style={{ 'margin-top':'5%'}} > {intl.formatMessage({ id: localizationKeys.general.mfumo })}</p>
//           </div>

//         <div class="col-2 col-md-3 float-right text-right my-auto"  >
//            <img src={logo} alt="NEC Logo"  width="90"  style={{ 'margin-left':'60%'}}/>
//           </div>


//       </div>
//     </nav>


//   );
// }

// export default Nav;
